var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1450px","persistent":"","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.permissionCan('create'))?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$emit('new')}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("MENU.NEW"))+" "+_vm._s(_vm.$t("MENU.bannerType"))+" ")]):_vm._e()]}}]),model:{value:(_vm.modalData.dialog),callback:function ($$v) {_vm.$set(_vm.modalData, "dialog", $$v)},expression:"modalData.dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle)+" ")]),_c('v-btn',{staticClass:"close-button",attrs:{"icon":""},on:{"click":_vm.handleCloseModalForm}},[_c('v-icon',[_vm._v(" mdi-close")])],1)],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[(_vm.statuses.pages)?_c('v-select',{attrs:{"items":_vm.statuses.pages.page,"label":"Status","item-text":"value","item-value":"key"},model:{value:(_vm.formModel.status),callback:function ($$v) {_vm.$set(_vm.formModel, "status", $$v)},expression:"formModel.status"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('FORM_INPUT_NAMES.name'),"id":_vm.dynamicID,"rules":_vm.requiredRules,"error-messages":_vm.messages['name']},on:{"keyup":function($event){_vm.messages['name'] = ''}},model:{value:(_vm.formModel.name),callback:function ($$v) {_vm.$set(_vm.formModel, "name", $$v)},expression:"formModel.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.bannerCollection,"chips":"","label":_vm.$t('MENU.banners'),"deletable-chips":"true","item-text":"text1","item-value":"id","multiple":"","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"bg-gray-100","close":""},on:{"click:close":function($event){return _vm.deleteChip(item, _vm.formModel.banners)}}},[_vm._v(_vm._s(item.text1)+" ")])]}}]),model:{value:(_vm.formModel.banners),callback:function ($$v) {_vm.$set(_vm.formModel, "banners", $$v)},expression:"formModel.banners"}})],1)],1),_c('draggable',{staticStyle:{"display":"flex","flex-direction":"row"},attrs:{"tag":"v-row"},model:{value:(_vm.draggableCards),callback:function ($$v) {_vm.draggableCards=$$v},expression:"draggableCards"}},_vm._l((_vm.files),function(file){return _c('v-col',{key:file.path,staticClass:"col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12"},[_c('v-card',[(_vm.previewable_mime_types.includes(file.file.mime_type))?_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.getFile(file.file),"height":"175","title":file.file.name}}):_c('a',{attrs:{"href":_vm.getFile(file.file),"target":"_blank"}},[_c('v-img',{attrs:{"src":_vm.getIcon(file.file),"title":file.file.name,"height":"175","contain":""}})],1),_c('v-card-actions',{staticStyle:{"height":"40px"}},[_vm._v(" "+_vm._s(file.bannerName)+" "),_c('v-spacer')],1)],1)],1)}),1)],1)],1),_c('v-card-actions',{staticClass:"mb-2"},[_c('v-spacer'),(
          (_vm.permissionCan('create') && !_vm.formModel.id) ||
          _vm.permissionCan('update')
        )?_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.handleSaveModalForm}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.save"))+" ")]):_vm._e()],1),_c('SnackBarInfoComponent',{attrs:{"snackbarInfo":_vm.snackbarInfo}})],1),_c('v-overlay',{attrs:{"value":_vm.loader}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }